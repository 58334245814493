<template>
  <section class="page-section dark" id="featured-hackathons" v-if="anyFeaturedHackathons">
    <div class="row">
      <div class="small-12 columns">
        <div v-if="onlineHackathons.length > 0">
          <div class="flex-row flex-wrap mb-4 mt-5">
            <h2 class="mb-4">Featured online hackathons</h2>

            <a :href="exploreOnlineHackathonsUrl" class="hide-for-small button radius">Explore online hackathons</a>
          </div>
          <hackathon-grid :hackathons="onlineHackathons" v-slot="props">
            <hackathon-result
              isMedium
              :hackathon="props.hackathon">
            </hackathon-result>
          </hackathon-grid>

          <div class="text-center hide-for-medium-up">
            <a :href="exploreOnlineHackathonsUrl" class="mb-5 mt-3 large button radius">Explore online hackathons</a>
          </div>
        </div>

        <div v-if="inPersonHackathons.length > 0">
          <div class="flex-row flex-wrap mt-5 mb-4">
            <h2 class="mb-4">Featured in-person hackathons</h2>

            <a :href="exploreInPersonHackathonsUrl" class="button hide-for-small radius">Explore in-person hackathons</a>
          </div>
          <hackathon-grid :hackathons="inPersonHackathons" v-slot="props">
            <hackathon-result
              isMedium
              :hackathon="props.hackathon">
            </hackathon-result>
          </hackathon-grid>

          <div class="text-center hide-for-medium-up">
            <a :href="exploreInPersonHackathonsUrl" class="button mb-5 mt-3 large radius">Explore in-person hackathons</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import { ref, computed } from 'vue';

  import HackathonApi from '@/api/hackathon_api';

  import HackathonResult from '@/components/HackathonResult';
  import HackathonGrid from '@/components/HackathonGrid';

  const inPersonHackathons = ref([]);
  const onlineHackathons = ref([]);

  const anyFeaturedHackathons = computed(() => inPersonHackathons.value.length || onlineHackathons.value.length);

  HackathonApi.getFeaturedInPerson().then((response) => {
    inPersonHackathons.value = response.hackathons;
  })

  HackathonApi.getFeaturedOnline().then((response) => {
    onlineHackathons.value = response.hackathons;
  })

  const exploreOnlineHackathonsUrl = CP.env.routes.explore_online_hackathons;

  const exploreInPersonHackathonsUrl = CP.env.routes.explore_in_person_hackathons;
</script>
