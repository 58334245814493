<template>
  <div class="themes" v-if="anyThemes">
    <info-with-icon icon="tags">
      <span
        v-for="(theme, index) in themes"
        @click.prevent="toggleTheme(theme)"
        @mouseenter="onMouseOver"
        @mouseleave="onMouseLeave"
        class="label theme-label mr-2 mb-2"
        :title="theme.name"
        :tabindex="index + 2"
        :key="theme.name"
        :class="{ selected: isSelected(theme.name) }">
        <font-awesome-icon :icon="['far', 'check-square']" class="checkmark"></font-awesome-icon>
        {{theme.name}}
      </span>
    </info-with-icon>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  import isEmpty from 'lodash/isEmpty';
  import includes from 'lodash/includes';
  import map from 'lodash/map';

  import InfoWithIcon from '@/components/InfoWithIcon.vue';
  import FontAwesomeIcon from '@/components/FontAwesomeIcon.vue';

  export default {
    name: "HackathonThemes",

    components: {
      InfoWithIcon,
      FontAwesomeIcon
    },

    props: {
      themes: {
        type: Object,
        required: true
      }
    },

    emits: ['onMouseOver', 'onMouseLeave'],

    setup(props, { emit }) {
      const store = useStore();

      return {
        anyThemes: !isEmpty(props.themes),

        isSelected(theme) {
          return includes(store.getters["filters/getSelectedThemeNames"], theme);
        },

        toggleTheme(theme) {
          store.dispatch("filters/toggleTheme", theme);
          store.dispatch("hackathons/search");
        },

        onMouseLeave() {
          emit("onMouseLeave");
        },

        onMouseOver() {
          emit("onMouseOver");
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .side-info {
    .checkmark {
      width: 13px;
      margin-right: 0.3rem;
      display: none;
    }

    .selected .checkmark {
      display: inherit;
    }
  }
</style>
