const registered = async () => {
  const url = CP.env.routes.user_hackathons_url;

  return await fetch(url).then((response) => {
    if (!response.ok)
      return { error: true, status: response.status };

    return response.json();
  });
}

const recommended = async () => {
  const url = CP.env.routes.recommended_hackathons_url;

  return await fetch(url).then((response) => {
    if (!response.ok)
      return { error: true, status: response.status };

    return response.json();
  });
}

export default {
  registered,
  recommended
}
