const get = async () => {
  const q = await fetch("/api/themes");
  return q.json();
}

const popular = async (params) => {
  const q = await fetch("/api/themes/popular");
  const result = await q.json();

  return result
}

export default {
  get,
  popular
}
