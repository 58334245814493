import { createStore, createLogger } from 'vuex'

import MixpanelPlugin from './plugins/mixpanel_plugin'

import hackathons from './modules/hackathon_store'
import filters from './modules/filter_store'
import sorting from './modules/sorting_store'
import initialization from './modules/initialization_store'

const debug = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'

let plugins = [
  MixpanelPlugin
]

if (debug) plugins.push(createLogger())

const store = createStore({
  mutations: {
    resetState(state, storedState) {
      this.replaceState(
        Object.assign(state, storedState)
      );
    }
  },
  modules: {
    hackathons,
    filters,
    sorting,
    initialization,
  },
  strict: debug,
  plugins: plugins
})

export default store
