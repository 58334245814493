import HackathonApi from "@/api/hackathon_api"
import debounce from 'lodash/debounce'

const state = () => ({
  hackathons: [],
  featuredHackathons: [],
  lastPageReached: false,
  totalResultCount: 0,
  page: 1,
  perPage: null,
  loading: false,
  scheduledRequestCount: 0,
  clearFilters: false,
  fuzzySearch: false,
  searchError: false
})

const mutations = {
  setLoading: (state, isLoading) => state.loading = isLoading,

  addHackathons: (state, hackathons) => {
    if (hackathons.length == 0) return;

    state.hackathons = [...state.hackathons, ...hackathons];
  },
  setHackathons: (state, hackathons) => state.hackathons = hackathons,

  setPage: (state, page) => state.page = page,

  lastPageReached: (state, isReached) => state.lastPageReached = isReached,

  setTotalResultCount: (state, count) => state.totalResultCount = count,

  setFeaturedHachathons: (state, hackathons) => {
    if (!hackathons) return state.featuredHackathons = [];

    state.featuredHackathons = hackathons;
  },
  setPerPage: (state, perPage) => state.perPage = perPage,

  scheduleRequest: (state) => state.scheduledRequestCount += 1,

  resetScheduledRequest: (state) => state.scheduledRequestCount = 0,

  setClearFilters: (state, value) => state.clearFilters = value,

  setFuzzySearch: (state, value) => state.fuzzySearch = value,

  setSearchError: (state, value) => state.searchError = value
}

const getters = {
  getHackathons: (state) => state.hackathons,

  isLoading: (state) => state.loading,

  getCurrentPage: (state) => state.page,

  lastPageReached: (state) => state.lastPageReached,

  totalResultCount: (state) => state.totalResultCount,

  getFeaturedHackathons: (state) => state.featuredHackathons,

  getPerPage: (state) => state.perPage,

  scheduledRequestCount: (state) => state.scheduledRequestCount,

  clearFilters: (state) => state.clearFilters,

  fuzzySearch: (state) => state.fuzzySearch,

  searchError: (state) => state.searchError
}

const actions = {
  searchByKeywords({ dispatch }) {
    dispatch("search", { clearFilters: true });
  },
  search: debounce(
    async ({ commit, dispatch, getters, rootGetters }, { page = 1, clearFilters = false } = {}) => {
      if (getters['isLoading']) {
        commit("setPage", page);
        commit("setClearFilters", clearFilters);
        commit("scheduleRequest");
        return;
      }

      commit("setLoading", true);
      commit("setSearchError", false);

      commit("setPage", page);

      commit("setClearFilters", clearFilters);
      if (getters["clearFilters"]) {
        dispatch("filters/clearFilters", null, { root: true });
      }

      dispatch("filters/setLastSearchQuery", null, { root: true });

      const results = await HackathonApi.get();

      if (results.error) {
        commit("setSearchError", true);
      } else {

        commit("setTotalResultCount", results.meta.total_count);
        commit("lastPageReached", results.hackathons.length < results.meta.per_page);
        commit(page == 1 ? "setHackathons" : "addHackathons", results.hackathons);
        commit("setPerPage", results.meta.per_page);
        commit("setFuzzySearch", results.meta.fuzzy);

        history.replaceState({}, document.title, HackathonApi.searchUrl.value);

        results.meta.total_count == 0 ?
          dispatch("fetchFeaturedHackathons") :
          commit("setFeaturedHachathons", null);
      }

      commit("setLoading", false);

      if (getters['scheduledRequestCount'] > 0) {
        commit("resetScheduledRequest");
        dispatch("search");
      }
    }, 300, { leading: true, trailing: true }
  ),
  fetchFeaturedHackathons: async ({ commit }) => {
    const results = await HackathonApi.getFeatured();

    if (results.error)
      return commit("setFeaturedHachathons", null);

    commit("setFeaturedHachathons", results.hackathons);
  },
  setPage({ commit }, page) {
    commit("setPage", page);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
