const getLocation = async () => {
  const url = CP.env.routes.user_location_url;

  return await fetch(url).then((response) => {
    if (!response.ok)
      return { error: true, status: response.status };

    return response.json();
  });
}

const setLocation = async (data) => {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const url = CP.env.routes.user_location_url;

  return await fetch(url,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      }
    }
  ).then((response) => {
    if (!response.ok)
      return { error: true, status: response.status };

    return response.json();
  });
}

export default {
  getLocation,
  setLocation
}
