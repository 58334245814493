<template>
  <div class="info-with-icon">
    <font-awesome-icon :icon="icon"></font-awesome-icon>

    <div class="info">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import FontAwesomeIcon from '@/components/FontAwesomeIcon.vue';

  export default {
    name: "InfoWithIcon",
    components: {
      FontAwesomeIcon
    },
    props: {
      icon: { type: [String, Array], required: true }
    }
  }
</script>
