<template>
  <div v-if="eligibility.loaded" class="flex-row justify-content-start">

    <component :is="divOrLink" :href="eligibility.url">

      <toggle-button
        v-model="eligibilityToggleState"
        :isLarge="mobileView"
        :disabled="!eligibility.eligibility_filled_in">
          <template v-slot:default="slotProps">

            <span class="mr-1" @click="slotProps.toggle">
              Match my eligibility
            </span>

            <a
              v-if="eligibility.user_signed_in"
              :href="eligibility.url"
              class="update-link">
              Update
            </a>
          </template>

      </toggle-button>

    </component>

  </div>
</template>

<script setup>
  import { computed, defineProps, ref } from 'vue';
  import { useStore } from 'vuex';

  import ToggleButton from '@/components/ToggleButton';
  import useIsMobile from '@/scripts/use_is_mobile';

  const props = defineProps({
    filter: { type: Object, required: true }
  })

  const eligibility = computed(() => props.filter.eligibilityData);

  const store = useStore();

  store.dispatch("filters/fetchEligibilityData");

  const { mobileView } = useIsMobile();

  const eligibilityToggleState = computed({
    get: () => store.getters['filters/getEligibilityToggleState'],
    set: (value) => {
      store.dispatch("filters/setEligibilityToggle", value);
      store.dispatch("hackathons/search");
    }
  })

  const divOrLink = computed(() => {
    return eligibility.value.eligibility_filled_in ? "div" : "a"
  })
</script>

<style scope lang="scss">
  a:focus {
    outline: none;
  }

  .update-link {
    font-size: 0.9375em;
  }
</style>
