import { ref, watch } from 'vue';
import onResize from '@/scripts/on_resize';

const isMobile = () => window.outerWidth < 1024;

const mobileView = ref(isMobile());
const desktopView = ref(!isMobile());

watch(mobileView, (value) => desktopView.value = !value);

onResize(
  () => mobileView.value = isMobile(),
  { wait: 200, isMounted: true }
);

// To account for the fact that `window.outerWidth` can return 0
// when the site is loaded in a different browser tab.
document.addEventListener("visibilitychange", () => {
  if (document.hidden || !window.dispatchEvent) return;
  window.dispatchEvent(new Event('resize'));
});

export default () => {
  return {
    mobileView,
    desktopView
  }
}
