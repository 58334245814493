<template>
  <div class="toggle-button flex-row justify-content-start">

    <div class="toggle-wrapper mr-2" :class="cssClasses" @click="toggle">
      <div class="toggle"></div>
    </div>

    <div class="toggle-label" :class="cssClasses">
      <slot :toggle="toggle"></slot>
    </div>
  </div>
</template>

<script setup>
  import { computed, defineProps, defineEmit } from 'vue';

  const props = defineProps({
    modelValue: { type: Boolean },
    isLarge: { type: Boolean },
    disabled: { type: Boolean, default: false }
  })

  const emit = defineEmit(['update:modelValue']);

  const toggle = (e) => {
    if (props.disabled) return;

    emit("update:modelValue", !props.modelValue);
  }

  const cssClasses = computed(() => {
    return {
      on: !props.disabled && props.modelValue,
      disabled: props.disabled,
      'is-large': props.isLarge
    }
  });
</script>

<style scope lang="scss">
  $large-ratio: 1.2;

  $toggle-width: 32px;
  $toggle-height: 20px;

  $toggle-large-width: $toggle-width * $large-ratio;
  $toggle-large-height: $toggle-height * $large-ratio;

  $padding: 3px;
  $border-width: 1px;

  $toggle-nob-dim: $toggle-height - 2 * $border-width - 2 * $padding;
  $toggle-large-nob-dim: $toggle-large-height - 2 * $border-width - 2 * $padding;

  $anim-speed: 0.15s;

  .toggle-button {
    cursor: pointer;
  }

  .toggle-wrapper {
    border: $border-width solid #B7C3C7;
    border-radius: 100px;
    background: #F5F7F7;
    width: $toggle-width;
    height: $toggle-height;
    position: relative;
    flex-shrink: 0;
    transition: background-color $anim-speed;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.on {
      border-color: #1F78D1;
      background: #BEE4F9;

      .toggle {
        left: $toggle-width - $toggle-nob-dim - $padding - 2 * $border-width;
        background-color: #1F78D1;
      }
    }

    &.disabled {
      border-color: #B7C3C7;
      background: #EDF1F2;

      .toggle {
        background-color: #B7C3C7;
      }
    }

    .toggle {
      height: $toggle-nob-dim;
      width: $toggle-nob-dim;
      position: absolute;
      left: $padding;
      top: $padding;
      background-color: #A4B8BF;
      border-radius: 100px;
      transition: left $anim-speed ease-in, background-color $anim-speed;
    }

    &.is-large {
      height: $toggle-large-height;
      width: $toggle-large-width;

      .toggle {
        height: $toggle-large-nob-dim;
        width: $toggle-large-nob-dim;
      }

      &.on {
        .toggle {
          left: $toggle-large-width - $toggle-large-nob-dim - $padding - 2 * $border-width;
        }
      }
    }
  }

  .toggle-label {
    color: #4B5C63;
    line-height: $toggle-height;

    &.is-large {
      line-height: $toggle-large-height;
    }

    &:not(.is-large) {
      font-size: 0.9375rem;
    }

    &.disabled {
      color: #9CAEB2;
    }
  }
</style>
