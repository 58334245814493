<template>
  <div class="flex-row justify-content-start align-items-center flex-wrap prizes-and-participants">
    <!-- Prize amount -->
    <div class="prize mr-4 mb-3">
      <span class="prize-amount" v-html="hackathon.prize_amount"></span> in prizes
    </div>

    <!-- participant count -->
    <div class="participants mr-4 mb-3">
      <strong>{{hackathon.registrations_count}}</strong> {{pluralize("participant", hackathon.registrations_count)}}
    </div>

    <div v-if="hackathon.winners_announced"
      @click.prevent="viewWinners"
      class="button radius small mb-3"
      >
      View winners
    </div>
  </div>
</template>

<script>
  import pluralize from '@/scripts/pluralize';

  export default {
    name: "HackathonStats",
    props: {
      hackathon: { type: Object, required: true }
    },
    setup(props) {
      return {
        pluralize,
        viewWinners() { window.location = props.hackathon.submission_gallery_url }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import 'colors';

  .prize {
    color: $blue-grey-5;
    min-width: 150px;
  }

  .prize-amount {
    color: #233136;

    &:deep() [data-currency-value] {
      font-weight: bold;
      font-size: 1rem;
      margin-left: 2px;
    }
  }

  .participants {
    color: $blue-grey-5;

    strong {
      color: $grey-5;
    }
  }

  .prizes-and-participants {
    margin-bottom: -0.75rem;
  }
</style>
