<template>
  <div class="tooltip-box"
       @click.prevent="showTip"
       v-outside-click:[tipActive]="hideTip"
       @mouseenter="showTip"
       @mouseleave="hideTip">
    <slot/>
    <div class="tooltip-vue" :class="{ active: tipActive }">
      <span class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue';

  export default {
    name: "ToolTipped",
    props: {
      text: {
        type: String,
        required: true
      }
    },
    setup(props) {
      let tipActive = ref(false)

      const showTip = () => {
        tipActive.value = true
      }

      const hideTip = () => {
        tipActive.value = false
      }

      return {
        tipActive,
        showTip,
        hideTip
      }
    }
  }
</script>

<style scoped lang="scss">
  @import 'colors';

  .tooltip-box {
    position: relative;
    display: inline-block;

    .tooltip-vue.active  {
      display: block;
    }

    .tooltip-vue {
      color: $grey-5;
      text-align: center;
      padding: .5em;
      border: 1px $grey-3 solid;
      border-radius: 3px;

      width: 200px;
      bottom: 100%;
      left: 50%;
      margin-left: -4em;

      display: none;

      position: absolute;
      z-index: 1;

      background: $grey-2;
    }

    .text::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border: 5px solid transparent;
      border-top-color: $grey-3;
    }
  }
</style>
