import queryString from 'query-string'

const get = async (params) => {
  const q = await fetch("/api/organizations?" + queryString.stringify(params));
  return q.json();
}

export default {
  get
}
