<template>
  <div class="row">
    <div class="small-12 columns">
      <div class="mt-7 mb-6" id="nearby-hackathons">

        <div class="">
          <div class="inline-block mb-2">
            <h2 class="mb-4 mr-3">In-person hackathons nearby</h2>

            <div class="inline-block mb-4">
              <span class="location">{{ userLocation }}</span>

              <a :href="editLocationUrl"
                @click="onEditLocation"
                class="ml-3 medium-font-weight">
                Edit location
                <i :class="iconClass" class="fas"></i>
              </a>
            </div>
          </div>

          <div class="row collapse">
            <div class="large-8 small-12">
              <form @submit.prevent="submitLocation" :class="{ hide: !editFormVisible }" class="flex-row flex-wrap align-items-baseline justify-content-start">
                <div id="input-wrap" class="mr-3 grow">

                  <input type="text" data-address="true" ref="inputRef" class="mr-3" @keyup="clearGeoMetaData">

                  <p data-error="" class="fieldWithErrors" v-if="validationMessage">
                    <span class="errors">{{ validationMessage }}</span>
                  </p>
                </div>
                <button id="update-location-button" class="mr-5">Update my location</button>

                <a href="#" @click.prevent="toggleEditLocationForm">Cancel</a>

                <div data-user-address ref="geoMetaDataRef">
                  <input type="hidden" name="lat" id="lat" value="" data-geo="lat">
                  <input type="hidden" name="lng" id="lng" value="" data-geo="lng">
                  <input type="hidden" name="country" id="country" value="" data-geo="country">
                </div>
              </form>
            </div>
          </div>
        </div>

        <hackathon-grid :hackathons="nearbyHackathons" v-slot="props"
          v-if="nearbyHackathons.length > 0 && !isLoading" class="mt-4">
          <hackathon-result
            isMedium
            :hackathon="props.hackathon"
            :isFeatured="false">
          </hackathon-result>
        </hackathon-grid>

        <div v-if="nearbyHackathons.length == 0 || isLoading" class="mt-4">
          <div :class="{ 'empty-hackathon-grid': true, 'loading': isLoading }" class="align-items-center justify-content-center">
            <div class="text-center py-7" v-if="!isLoading">
              <h3>No hackathons found near you</h3>
              <a :href="searchURL" class="button large radius mt-2 mb-0">Browse all hackathons</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, computed } from 'vue';

  import HackathonApi from '@/api/hackathon_api';
  import LocationApi from '@/api/users/location_api';

  import HackathonResult from '@/components/HackathonResult';
  import HackathonGrid from '@/components/HackathonGrid';

  const nearbyHackathons = ref([]);

  const editFormVisible = ref(false);

  const inputRef = ref(null);
  const geoMetaDataRef = ref(null);

  const userLocation = ref("");

  const editLocationUrl = CP.userSignedIn ? "" : CP.env.routes.sign_up_url;

  const searchURL = CP.env.routes.search_url;
  const isLoading = ref(false);

  const validationMessage = ref(null);

  let previousLocationValue = "";

  HackathonApi.getNearby().then((response) => {
    nearbyHackathons.value = response.hackathons;
  })

  if (CP.userSignedIn) {
    LocationApi.getLocation().then((response) => {
      userLocation.value = response.address;
    })
  }

  const onEditLocation = (e) => {
    if (!CP.userSignedIn) return;

    e.preventDefault();

    toggleEditLocationForm();
  }

  const toggleEditLocationForm = () => {
    editFormVisible.value = !editFormVisible.value;
  }

  const iconClass = ref({
    'fa-arrow-right': computed(() => !editFormVisible.value),
    'fa-angle-up': computed(() => editFormVisible.value)
  })

  const locationInputValue = () => inputRef.value.value;

  const submitLocation = () => {
    if (!formIsValid()) {
      validationMessage.value = "Please select a result from the dropdown.";
      return
    }

    if (locationInputValue()) {
      isLoading.value = true;

      LocationApi.setLocation({ address: locationInputValue() }).then((response) => {
        editFormVisible.value = false;
        userLocation.value = response.address;
        CP.MixpanelProxy.track('Homepage location update');

        HackathonApi.getNearby().then((response) => {
          isLoading.value = false;
          nearbyHackathons.value = response.hackathons;
        })
      });
    }
  }

  const clearGeoMetaData = () => {
    if (locationInputValue() == previousLocationValue) return;

    for (var geoField of geoMetaDataRef.value.children) {
      geoField.value = "";
    }
  }

  const formIsValid = () => !!geoMetaDataRef.value.firstChild.value;

  onMounted(() => {
    $(inputRef.value).geocomplete({
      details: "[data-user-address]",
      detailsAttribute: "data-geo"
    }).on("geocode:result", (e) => {
      previousLocationValue = locationInputValue();
      validationMessage.value = "";
    });
  })

</script>

<style scoped lang="scss">
  @import "colors";

  h2 {
    display: inline-block;
  }

  #input-wrap {
    max-width: 475px;
  }

  .location {
    color: $blue-grey-5;
  }

  #update-location-button {
    border-radius: 3px;
  }

  .hide {
    display: none !important;
  }

  @keyframes loading {
    0%   { opacity: 1; }
    50%  { opacity: 0; }
    100% { opacity: 1; }
  }

  .loading {
    opacity: 1;
    animation: loading 2s infinite;
  }

  .medium-font-weight {
    font-weight: 500;
  }
</style>
