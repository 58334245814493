import reduce from 'lodash/reduce'
import debounce from 'lodash/debounce'
import sumBy from 'lodash/sumBy'
import each from 'lodash/each'
import findKey from 'lodash/findKey'
import some from 'lodash/some'

import OrganizationApi from "@/api/organization_api"
import ThemeApi from '@/api/theme_api'
import UserEligibilityApi from '@/api/user_eligibility_api'

import { markRaw } from 'vue';
import SearchCheckBoxes from '@/components/SearchCheckBoxes.vue';
import SearchDropdown from '@/components/SearchDropdown.vue';
import EligibilityToggle from '@/components/EligibilityToggle.vue';
import ManagedByDevpostCheckBox from '@/components/ManagedByDevpostCheckBox.vue';
const SearchCheckBoxesComponent = markRaw(SearchCheckBoxes);
const SearchDropdownComponent = markRaw(SearchDropdown);
const EligibilityToggleComponent = markRaw(EligibilityToggle);
const ManagedByDevpostCheckBoxComponent = markRaw(ManagedByDevpostCheckBox);

const state = () => ({
  query: "",
  lastSearchQuery: "",
  filters: {
    eligibility: {
      component: EligibilityToggleComponent,
      paramName: "eligibility",
      selected: false,
      eligibilityData: {
        loaded: false
      }
    },
    managed_by_devpost_badge: {
      component: ManagedByDevpostCheckBoxComponent,
      paramName: "managed_by_devpost_badge",
      value: "1",
      selected: false
    },
    challenge_type: {
      component: SearchCheckBoxesComponent,
      heading: "Location",
      paramName: "challenge_type",
      items: [
        {
          label: "Online",
          value: "online",
          selected: false
        },
        {
          label: "In-person",
          value: "in-person",
          selected: false
        }
      ]
    },
    status: {
      component: SearchCheckBoxesComponent,
      heading: "Status",
      paramName: "status",
      items: [
        {
          label: "Upcoming",
          value: "upcoming",
          selected: false
        },
        {
          label: "Open",
          value: "open",
          selected: false
        },
        {
          label: "Ended",
          value: "ended",
          selected: false
        }
      ]
    },
    length: {
      component: SearchCheckBoxesComponent,
      heading: "Length",
      mobileHeading: "Length",
      paramName: "length",
      items: [
        {
          label: "1–6 days",
          value: "days",
          selected: false
        },
        {
          label: "1–4 weeks",
          value: "weeks",
          selected: false
        },
        {
          label: "1+ month",
          value: "months",
          selected: false
        }
      ]
    },
    themes: {
      component: SearchCheckBoxesComponent,
      heading: "Interest tags",
      mobileHeading: "Interests",
      paramName: "themes",
      clearable: true,
      items: [],
      defaultMaxItemCount: 5,
      allFiltersShown: null,
      displaySelectedCount: true
    },
    organization: {
      component: SearchDropdownComponent,
      heading: "Host",
      paramName: "organization",
      selected: null,
      items: []
    },
    open_to: {
      component: SearchCheckBoxesComponent,
      heading: "Open to",
      paramName: "open_to",
      items: [
        {
          label: "Public",
          value: "public",
          selected: false
        },
        {
          label: "Invite only",
          value: "invite_only",
          selected: false
        }
      ]
    }
  },
  pills: {
    challenge_type: {
      heading: "Location",
      name: "challenge_type",
      selected: false,
      highlighted: false
    },
    length: {
      heading: "Length",
      name: "length",
      selected: false,
      highlighted: false
    },
    themes: {
      heading: "Interests",
      name: "themes",
      selected: false,
      highlighted: false
    },
    more: {
      heading: "More",
      name: "more",
      selected: false,
      highlighted: false
    }
  }
})

const h = {
  findByParamName: (state, paramName) => {
    return state.filters.find((element) => element.paramName == paramName)
  }
}

const mutations = {
  toggleItem(state, item) { item.selected = !item.selected },

  setThemes(state, themes) {
    state.filters.themes.items = themes;
  },

  toggleTheme(state, themeToSelect) {
    const themeItem = state.filters.themes.items.find((theme) => theme.name == themeToSelect.name);

    themeItem.selected = !themeItem.selected;
  },

  updateQuery: (state, query) => state.query = query,

  setLastSearchQuery: (state) => state.lastSearchQuery = state.query,

  clearFilters(state) {
    each(state.filters, (filter) => {
      if ("selected" in filter) {
        filter.selected = null;
      } else {
        each(filter.items, (item) => {
          item.selected = false;
        })
      }
    })
  },

  clear: (state, filterParamName) => {
    const filter = state.filters[filterParamName];

    each(filter.items, (item) => {
      item.selected = false;
    })
  },

  setSelectedOrganization: (state, organization) => state.filters.organization.selected = organization,

  setOrganizations: (state, organizations) => state.filters.organization.items = organizations,

  showMore: (state, paramName) => state.filters[paramName].allFiltersShown = true,
  showLess: (state, paramName) => state.filters[paramName].allFiltersShown = false,

  togglePill: (state, pill) => pill.selected = !pill.selected,

  closeAllPills(state) {
    each(state.pills, (pill) => {
      pill.selected = false;
    });
  },

  setEligibilityToggle(state, selectedState) {
    state.filters.eligibility.selected = selectedState;
  },

  setManagedByDevpostBadge(state, selectedState) {
    state.filters.managed_by_devpost_badge.selected = selectedState;
  },

  setEligibilityData(state, eligibilityData) {
    state.filters.eligibility.eligibilityData = {
      ...eligibilityData,
      loaded: true
    }
  }
}

const getters = {
  getQuery: (state) => state.query,
  getLastSearchQuery: (state) => state.lastSearchQuery,

  getFilters: (state) => state.filters,
  getChallengeTypeFilter: (state, getters) => getters['getFilters']['challenge_type'],
  getLengthFilter: (state, getters) => getters['getFilters']['length'],
  getStatusFilter: (state, getters) => getters['getFilters']['status'],
  getThemesFilter: (state, getters) => getters['getFilters']['themes'],
  getOrganizationFilter: (state, getters) => getters['getFilters']['organization'],
  getOpenToFilter: (state, getters) => getters['getFilters']['open_to'],
  getEligibilityFilter: (state, getters) => getters['getFilters']['eligibility'],
  getManagedByDevpostBadgeFilter: (state, getters) => getters['getFilters']['managed_by_devpost_badge'],

  getPills: (state) => state.pills,

  selectedPill(state, getters) {
    const pills = getters["getPills"];

    const selectedPillName = findKey(pills, (pill) => pill.selected);

    return selectedPillName ? pills[selectedPillName] : null;
  },

  getSelectedFilterItems(state, getters) {
    const selectedFilters = reduce(getters["getFilters"], (filtersHash, filter, paramName) => {
      if (paramName == "organization") {
        if (getters["getSelectedOrganizationName"])
          filtersHash[paramName] = getters["getSelectedOrganizationName"];

        return filtersHash;
      }

      if (paramName == "eligibility") {
        if (getters["getEligibilityToggleState"])
          filtersHash[paramName] = 1;

        return filtersHash;
      }

      if (paramName == "managed_by_devpost_badge") {
        if (getters["getManagedByDevpostBadgeState"])
          filtersHash[paramName] = 1;

        return filtersHash;
      }

      const selectedItems = _.reduce(filter.items, (selectedItemList, item) => {
        if (item.selected) { selectedItemList.push(item.value) }
        return selectedItemList;
      }, []);

      if (selectedItems.length > 0) filtersHash[paramName] = selectedItems;

      return filtersHash;
    }, {});

    return selectedFilters;
  },

  getEligibilityToggleState(state, getters) {
    return getters["getEligibilityFilter"].selected;
  },

  getManagedByDevpostBadgeState(state, getters) {
    return getters["getManagedByDevpostBadgeFilter"].selected;
  },

  getSelectedThemeNames(state, getters) {
    return reduce(getters["getThemeItems"], (selectedThemeNames, theme) => {
      if (theme.selected) selectedThemeNames.push(theme.name);

      return selectedThemeNames;
    }, []);
  },

  getThemeItems: (state) => state.filters.themes.items,

  getOrganizations: (state) => state.filters.organization.items,

  getSelectedOrganization: (state) => state.filters.organization.selected,

  getSelectedOrganizationName(state, getters) {
    if (!getters["getSelectedOrganization"]) return null;

    return getters["getSelectedOrganization"].name;
  },

  getSelectedThemesCount(state) {
    return sumBy(state.filters.themes.items, (item) => item.selected ? 1 : 0);
  },

  selectedFilterItemCount(state, getters) {
    const toArray = (value) => Array.isArray(value) ? value : [value];

    return reduce(getters["getSelectedFilterItems"], (count, filterValues, paramName) => {
      return count + toArray(filterValues).length;
    }, 0)
  },

  anyPillOpened(state) {
    return some(state.pills, (pill, key) => {
      return pill.selected;
    })
  }
}

const actions = {
  toggleItem({ state, commit }, { paramName, value }) {
    let item = state.filters[paramName].items.find((item) => item.value == value);
    if (!item) return;

    commit("toggleItem", item);
  },

  async fetchThemes({ dispatch }) {
    const themes = await ThemeApi.get();
    dispatch("mergeThemes", themes);
  },

  mergeThemes({ commit, getters }, themes) {
    // Themes can be set in the store, in a non-deterministic order,
    // when they are fetched from the backend, or when they are present in the url,
    // so we need to merge the themes passed to this method with the existing ones.
    const currentThemes = getters["getThemeItems"] || [];

    _.each(themes, (theme) => {
      theme.value = theme.name;
      theme.label = theme.name;

      const existingTheme = currentThemes.find((item) => item.value == theme.name);
      theme.selected = (existingTheme && existingTheme.selected) || !!theme.selected;
    })

    commit("setThemes", themes);
  },

  toggleTheme: ({ commit }, theme) => commit("toggleTheme", theme),

  searchOrganizations: debounce(
    async ({ commit }, term) => {
      const results = await OrganizationApi.get({ term: term });
      commit("setOrganizations", results);
  }, 200, { leading: false, trailing: true }),

  updateQuery: ({ commit }, query) => commit("updateQuery", query),
  clearQuery: ({ commit }) => commit("updateQuery", ""),
  setLastSearchQuery: ({ commit }) => commit("setLastSearchQuery"),

  clearFilters: ({ commit }) => commit("clearFilters"),
  clear: ({ commit }, filterParamName) => commit("clear", filterParamName),

  setSelectedOrganization({ commit, getters }, organization) {
    const currentOrganization = getters["getSelectedOrganization"];

    if (!organization && !currentOrganization) return;
    if (organization && currentOrganization && organization.name == currentOrganization.name) return;

    commit("setSelectedOrganization", organization);
  },

  showLess: ({ commit }, paramName) => commit("showLess", paramName),
  showMore: ({ commit }, paramName) => commit("showMore", paramName),

  togglePillByName({ state, commit, getters }, pillName) {
    const selectedPill = getters["selectedPill"];

    if (selectedPill && selectedPill.name != pillName)
      commit("togglePill", selectedPill);

    commit("togglePill", state.pills[pillName]);
  },

  closeAllPills: ({ commit }) => commit('closeAllPills'),

  setEligibilityToggle: ({ commit }, value) => {
    commit("setEligibilityToggle", value);
    window.localStorage.setItem("eligibilityFilterSelected", value);
  },

  setManagedByDevpostBadge: ({ commit }, value) => {
    commit("setManagedByDevpostBadge", value);
  },

  fetchEligibilityData: async ({ commit }) => {
    const eligibility = await UserEligibilityApi.get();
    commit("setEligibilityData", eligibility);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
