<template>
  <a href="#" class="clear-link" v-if="showFilter()" @click.prevent="clear">Clear</a>
</template>

<script>
  import { useStore } from 'vuex';
  import some from 'lodash/some';

  export default {
    name: "ClearButton",

    props: {
      filter: { type: Object, required: true }
    },

    setup(props) {
      const store = useStore();
      const filter = props.filter;

      return {
        clear() {
          store.dispatch("filters/clear", filter.paramName);
          store.dispatch("hackathons/search");
        },

        showFilter() {
          return filter.clearable && some(filter.items, 'selected');
        }
      }
    },
  }
</script>
