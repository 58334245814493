<template>
  <div>
    <info-with-icon :icon="hackathon.displayed_location.icon">
      <span v-if="hackathon.displayed_location.location">
        {{hackathon.displayed_location.location}}
      </span>
      <span v-else class="faded">No location yet</span>
    </info-with-icon>
  </div>
</template>

<script>
  import InfoWithIcon from '@/components/InfoWithIcon.vue';

  export default {
    name: "HackathonLocation",

    components: {
      InfoWithIcon
    },

    props: {
      hackathon: { type: Object, required: true }
    }
  }
</script>
