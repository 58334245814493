<template>
  <div v-if="displayedItems.length != 0">

    <h4 class="mb-2" v-if="displayHeading">
      {{filter.heading}}

      <span class="count ml-1" v-if="filter.displaySelectedCount && selectedItemCount">
        {{selectedItemCount}}
      </span>

    </h4>

    <clear-button :filter='filter' class="ml-4" v-if="desktopView"></clear-button>

    <div class="flex-row flex-wrap justify-content-start" v-if="mobileView">
      <div v-for="item in allItems"
        :key="item.value"
        class="checkbox-button px-4 py-3 mr-3 mb-3"
        :class="[labelClass(item), { selected: item.selected }]"
        @click="toggleItem(item)">

        {{item.label}}
      </div>
    </div>

    <template v-else>
      <div v-for="item in displayedItems" :key="item.value" class="flex-row">
        <label :for="item.value" class="px-2 py-2 flex-row align-items-baseline" :class="labelClass(item)">
          <input type="checkbox"
            :id="item.value"
            :name="filter.paramName"
            :value="item.value"
            @change="toggleItem(item)"
            :checked="item.selected">

          <span class="item-label">{{item.label}}</span>
        </label>
      </div>

      <div v-if="filter.defaultMaxItemCount" class="mt-2">
        <a href="#" class="link" v-if="filter.allFiltersShown" @click.prevent="showLess">
          Show less
        </a>
        <a v-else-if="hiddenItemsCount > 0" href="#" class="link" @click.prevent="showMore">
          Show more ({{hiddenItemsCount}})
        </a>
      </div>
    </template>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  import slice from 'lodash/slice';
  import some from 'lodash/some';
  import capitalize from '@/scripts/capitalize';
  import useIsMobile from '@/scripts/use_is_mobile';

  import ClearButton from '@/components/ClearButton.vue';

  export default {
    name: "SearchCheckBoxes",
    components: {
      ClearButton
    },
    props: {
      filter: { type: Object, required: true },
      displayHeading: { type: Boolean, required: false, default: true }
    },
    setup(props) {
      const store = useStore();

      const { mobileView, desktopView } = useIsMobile();

      const filter = props.filter;

      const itemsShownByDefault = () => {
        return slice(filter.items, 0, filter.defaultMaxItemCount);
      }

      const anyHiddenFilterSelected = () => {
        const hiddenFilters = slice(filter.items, filter.defaultMaxItemCount, filter.items.length);

        return some(hiddenFilters, (filter) => filter.selected);
      }

      const displayedItems = computed(() => {
        if (!('allFiltersShown' in filter))
          return filter.items;

        if (filter.allFiltersShown) return filter.items;

        if (filter.allFiltersShown == false) return itemsShownByDefault();

        if (anyHiddenFilterSelected()) {
          store.dispatch("filters/showMore", filter.paramName);
          return filter.items;
        }

        return itemsShownByDefault();
      });

      const toggleItem = (item) => {
        store.dispatch("filters/toggleItem", { paramName: filter.paramName, value: item.value });
        store.dispatch("hackathons/search");
      }

      return {
        displayedItems,
        allItems: computed(() => filter.items),

        toggleItem,

        hiddenItemsCount: computed(() => Math.max(props.filter.items.length - props.filter.defaultMaxItemCount, 0)),

        selectedItemCount: computed(() => store.getters[`filters/getSelected${capitalize(props.filter.paramName)}Count`]),

        showMore() { store.dispatch("filters/showMore", filter.paramName) },
        showLess() { store.dispatch("filters/showLess", filter.paramName) },

        labelClass(item) {
          return `${filter.paramName}-${item.value}`;
        },

        mobileView,
        desktopView
      }
    }
  }
</script>

<style scoped lang="scss">
  @import 'hackathon_search/filters';

  h4 {
    text-transform: none;
    font-size: 0.9375rem;
    letter-spacing: 0;
    display: inline-block;
  }

  h4 .count {
    display: inline-block;
    font-weight: normal;
    background: $grey-2;
    border-radius: 3px;
    padding: 0 4px;
    font-size: 14px;
    line-height: 1.3;
  }

  input {
    margin-bottom: 0px;
  }

  .item-label:after {
    content: "";
    position: absolute;
    right: -15px;
    top: 50%;
    margin-top: -3px;
    height: 8px;
    width: 8px;
    border-radius: 8px;
  }

  .status-upcoming .item-label:after {
    background: $upcoming-color;
  }

  .status-open .item-label:after {
    background: $open-color;
  }

  .status-ended .item-label:after {
    background: $ended-color;
  }

  .checkbox-button {
    border: 1px solid $dark;
    border-radius: 3px;
    cursor: pointer;
    transition: none;

    &.selected {
      background-color: $dark;
      color: white;
    }

    &:hover:not(.selected) {
      box-shadow: 0 0 0 2px $blue-grey-2;
    }
  }
</style>
