<template>
  <div class="select-wrapper" :class="{ opened: opened, selected: !!modelValue }" v-outside-click:[opened]="close">
    <div class="current-selection-wrapper">
      <div class="flex-row">

        <input type="text" autocomplete="off"
          ref="queryInputRef"
          :name="name"
          @input="search"
          :placeholder="placeholder"
          v-model="inputValue"
          class="query-input grow"
        >

        <a href="#" class="current-selection grow" @click.prevent="open" ref="currentSelectionRef">
          <div class="current-selection-inner" ref="currentSelectionInnerRef">
            <template v-if="modelValue">
              <slot name="selected-option" :option="modelValue">
                {{modelValue.name}}
              </slot>
            </template>
            <template v-else>
              <slot name="select-an-option">
                Select an option
              </slot>
            </template>
          </div>
          <div v-if="overflow" class="overflow"></div>
        </a>

        <a href="#" class="shrink close-button icon" v-if="modelValue" @click.prevent="clearSelection">
          <font-awesome-icon icon="times"></font-awesome-icon>
        </a>

        <a href="#" class="shrink caret icon" @click.prevent="toggle">
          <font-awesome-icon :icon="caretIconName"></font-awesome-icon>
        </a>
      </div>
    </div>

    <div class="options-wrapper">
      <div v-if="anyOptions" ref="optionsContainerRef">
        <div v-for="(option, i) in options"
          @click="selectOption(option, i)"
          @mouseover="onOptionMouseOver(i)"
          class="option px-2 py-2"
          :class="{ selected: (i + 1 == selectedIndex) }"
          :key="option.name">

          <slot name="option" :option="option">
            {{ option.name }}
          </slot>

        </div>
      </div>

      <div v-else class="px-2 py-2">
        <!-- should be a slot -->
        <slot name="no-results" :inputValue="inputValue">
          No results found.
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, watch, ref, nextTick } from 'vue';
  import isEmpty from 'lodash/isEmpty';

  import FontAwesomeIcon from '@/components/FontAwesomeIcon.vue';

  import { useKeypress } from '@/scripts/keypress';
  import scrollIntoView from "@/scripts/scroll_into_view";
  import onResize from '@/scripts/on_resize';

  export default {
    name: "DevSelect",

    props: {
      options:      { type: Object,           required: true },
      modelValue:   { type: [Object, String] },
      name:         { type: String,           required: true },
      placeholder:  { type: String }
    },

    components: {
      FontAwesomeIcon
    },

    emits: ['search', 'update:modelValue'],

    setup(props, { emit }) {
      const queryInputRef = ref(null);
      const optionsContainerRef = ref(null);
      const currentSelectionRef = ref(null);
      const currentSelectionInnerRef = ref(null);
      const inputValue = ref("");
      const selectedIndex = ref(1);
      const overflow = ref(false);

      // Open/close state of the dropdown
      const opened = ref(false);

      useKeypress({
        keyEvent: "keydown",
        keyBinds: [
          {
            keyCode: "down",
            success: () => incrementSelectedIndex(1)
          },
          {
            keyCode: "up",
            success: () => incrementSelectedIndex(-1)
          },
          {
            keyCode: "enter",
            success: () => selectCurrentOption()
          },
          {
            keyCode: "tab",
            success: () => incrementSelectedIndex(1)
          },
          {
            keyCode: "tab",
            modifiers: ["shiftKey"],
            success: () => incrementSelectedIndex(-1)
          },
          {
            keyCode: "esc",
            success: () => close()
          }
        ],
        isActive: opened
      });

      const close = () => opened.value = false;

      const open = async () => {
        opened.value = true;

        await nextTick();
        queryInputRef.value.focus();
      }

      const toggle = () => opened.value ? close() : open();

      const addOrRemoveOverflowGradient = async () => {
        if (!currentSelectionInnerRef.value || !currentSelectionRef.value) return;

        await nextTick();
        overflow.value = currentSelectionInnerRef.value.offsetWidth + 16 > currentSelectionRef.value.offsetWidth;
      }

      onResize(addOrRemoveOverflowGradient, { wait: 200 });

      const selectOption = (option, i) => {
        emit('update:modelValue', option);
        if (i != null) selectedIndex.value = i + 1;

        addOrRemoveOverflowGradient();

        close();
      }

      const updateSelectedIndex = (value) => {
        selectedIndex.value = value;

        if (!(optionsContainerRef && optionsContainerRef.value)) return;

        // Scrolls the container to make the currently selected item visible.
        scrollIntoView({
          scrollableEl: optionsContainerRef.value.parentElement,
          el: optionsContainerRef.value.children.item(value - 1)
        })
      }

      const incrementSelectedIndex = (i) => {
        const newIndex = selectedIndex.value + i;

        if (newIndex > props.options.length) return updateSelectedIndex(1);
        if (newIndex <= 0) return updateSelectedIndex(props.options.length);

        updateSelectedIndex(newIndex);
      }

      const selectCurrentOption = () => {
        selectOption(props.options[selectedIndex.value - 1]);
      }

      return {
        search(e) {
          emit('search', e.target.value);
          selectedIndex.value = 1;
        },

        clearSelection() {
          selectedIndex.value = 1;
          emit('update:modelValue', null);
          close();
        },

        onOptionMouseOver(i) {
          selectedIndex.value = i + 1;
        },

        selectOption,
        selectedIndex,
        anyOptions: computed(() => !isEmpty(props.options)),

        inputValue,

        opened,
        open,
        close,
        toggle,

        queryInputRef,
        optionsContainerRef,
        currentSelectionRef,
        currentSelectionInnerRef,

        overflow,

        caretIconName: computed(() => opened.value ? "angle-up" : "angle-down"),
      }
    }
  }
</script>

<style scoped lang="scss">
  @import 'colors';

  .select-wrapper {
    border: 1px solid $blue-grey-3;
    border-radius: 3px;
    position: relative;
  }

  .select-wrapper.selected, .selected .options-wrapper {
    border-color: $primary-1;
  }

  .select-wrapper input {
    background: none;
    border: 0;
    margin: 0;
  }

  .options-wrapper {
    background: white;
    border: 1px solid $blue-grey-3;
    border-radius: 0 0 3px 3px;
    margin: -1px -1px 0 -1px;

    position: absolute;
    z-index: 10000;
    top: 100%;
    left: 0;
    right: 0;

    max-height: 300px;
    overflow-y: scroll;

    display: none;
  }

  .opened .options-wrapper {
    display: block;
  }

  .options-wrapper .option {
    cursor: pointer;
  }

  .options-wrapper .option.selected {
    background: $dark;
    color: white;
  }

  .opened .current-selection {
    display: none;
  }

  .current-selection {
    line-height: 1.6;
    position: relative;
    overflow: hidden;

    .current-selection-inner {
      position: absolute;
      white-space: nowrap;
    }
  }

  .query-input {
    display: none;
  }
  .opened .query-input {
    display: inherit;
  }

  .current-selection-wrapper {
    display: block;
    height: 40px;
    line-height: 24px;
  }

  .current-selection, .caret {
    cursor: text;
  }

  .current-selection {
    padding-right: 0;
  }

  .close-button, .caret {
    padding: 0.5em 0.75rem;
  }

  .current-selection {
    padding: 0.5rem;
  }

  .caret {
    border-left: 1px solid $blue-grey-3;

    .selected & {
      border-color: $primary-1;
    }
  }

  a {
    color: $grey-5;
  }

  .overflow {
    background: linear-gradient(to right, transparent 0%, #fff 100%);
    position: absolute;
    right: 0;
    top: 1px;
    bottom: 1px;
    width: 50px;
  }
</style>
