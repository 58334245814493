<template>
  <i :class="iconClass"></i>
</template>

<script>
  const normalizeIconArgs = (icon) => {
    if (Array.isArray(icon)) {
      if (icon.length === 2) {
        return { prefix: icon[0], iconName: icon[1] };
      } else {
        return { prefix: 'fas', iconName: icon[0] };
      }
    }

    if (typeof icon === 'string') {
      return { prefix: 'fas', iconName: icon }
    }

    return {};
  }

  export default {
    name: "FontAwesomeIcon",
    props: {
      // <font-awesome-icon icon='globe'></font-awesome-icon>
      // <font-awesome-icon :icon='[globe]'></font-awesome-icon>
      // <font-awesome-icon :icon='["fas", "globe"]'></font-awesome-icon>
      icon: {
        type: [String, Array],
        required: true,
        validator: (iconArgs) => {
          const icon = normalizeIconArgs(iconArgs);

          if (!icon.iconName) return false;

          return ["fas", "far", "fab"].indexOf(icon.prefix) !== -1;
        }
      },
    },
    setup(props) {
      const icon = normalizeIconArgs(props.icon);

      return {
        iconClass: `${icon.prefix} fa-${icon.iconName}`
      }
    }
  }
</script>
