<template>
  <div class="flex-row flex-wrap align-items-baseline justify-content-start mb-5">
    <h2 class="mr-5 is-marginless">Hackathons for you</h2>

    <a v-if="eligibilityUrl" :href="eligibilityUrl" class="edit-settings my-3">
      Edit your recommendations <i class="fas fa-arrow-right"></i>
    </a>
  </div>


  <hackathon-grid :hackathons="hackathons" v-slot="props">
    <hackathon-result
      :isFeatured="false"
      isMedium
      :hackathon="props.hackathon"
      :refParams="{ ref_feature: 'challenge', ref_medium: 'homepage-recommended-hackathons' }">
    </hackathon-result>
  </hackathon-grid>
</template>

<script setup>
  import { ref } from 'vue';

  import HackathonGrid from "@/components/HackathonGrid";
  import HackathonResult from "@/components/HackathonResult";

  import UserHackathonApi from "@/api/users/hackathon_api";
  import UserEligibilityApi from '@/api/user_eligibility_api';

  const hackathons = ref([]);

  UserHackathonApi.recommended().then((response) => {
    hackathons.value = response.hackathons;
  })

  const eligibilityUrl = ref("");

  UserEligibilityApi.get().then((response) => {
    eligibilityUrl.value = response.url.split("#")[0];
  })
</script>

<style scoped lang="scss">
  .edit-settings {
    font-weight: 500;
  }
</style>
