<template>
  <div>
    <h2>Top hackathon themes</h2>

    <table>
      <tr id="theme-table-head">
        <th colspan="2">Theme</th>
        <th>
          <span class="hide-for-small">Hackathons</span>
          <span class="hide-for-medium-up">#</span>
        </th>
        <th colspan="2">Total prizes</th>
      </tr>
      <tr id="separator"></tr>
      <tr v-for="(theme, i) in themes" :key="theme.id">
        <td>
          <a :href="theme.url">
          {{i + 1}}.
          </a>
        </td>
        <td class="theme-name">
          <a :href="theme.url">
            <span class="label theme-label">{{theme.name}}</span>
          </a>
        </td>
        <td>
          <a :href="theme.url">
            {{theme.current_challenges_count}}
          </a>
        </td>
        <td>
          <a :href="theme.url">
            {{theme.formatted_current_usd_prize_amount}}
          </a>
        </td>
        <td>
          <a :href="theme.url" class="text-center">
            <i class="fas fa-arrow-right"></i>
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  import ThemeApi from "@/api/theme_api";

  const themes = ref([]);

  ThemeApi.popular().then((response) => {
    themes.value = response.themes;
  })
</script>

<style scoped lang="scss">
  @import 'colors';

  $gap: 1rem;

  a {
    outline: none;
  }

  h2 {
    padding-left: $gap;
  }

  #theme-table-head {
    position: relative;

    th {
      color: $blue-grey-5;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  table {
    border: 0;

    tr {
      background: none;
      border-radius: 3px;

      td {
        padding: 0;
        background: none;

        a {
          display: block;
          padding: 0.75rem 0 0.75rem $gap;
          color: $grey-5;
        }

        &.theme-name a {
          padding-left: 0.5rem;
        }

        &:last-child a {
          padding-right: $gap;
        }
      }

      i {
        position: relative;
        left: 0;
        transition: left 0.15s;
        color: #1F78D1;
      }

      &:not(#theme-table-head):hover {
        background: #F1F8FF;

        .theme-label {
          background: #D3E2F5;
        }

        i {
          left: 3px
        }
      }
    }
  }

  th {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background: $blue-grey-2;
    }

    &:first-child:after {
      border-left: $gap solid white;
    }
    &:last-child:after {
      border-right: $gap solid white;
    }
  }

  #separator {
    height: 0.75rem;
  }

  .theme-label, tr {
    transition: background 0.15s;
  }
</style>
