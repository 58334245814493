import QueryString from 'query-string'
import extend from 'lodash/extend'

import { computed } from 'vue';

import store from '@/stores/index';

const searchQueryString = computed(() => {
  const selectedFilterItems = store.getters["filters/getSelectedFilterItems"];
  const defaultOrderingOption = store.getters["sorting/getDefaultSortingOption"];
  const orderingOption = store.getters["sorting/getSortingOptionValue"];
  const currentPage = store.getters["hackathons/getCurrentPage"];
  const searchQuery = store.getters["filters/getQuery"];

  let query = extend(
    (orderingOption != defaultOrderingOption) ? { order_by: orderingOption } : {},
    (currentPage > 1) ? { page: currentPage } : {},
    searchQuery ? { search: searchQuery } : {},
    selectedFilterItems
  );

  return QueryString.stringify(query, { arrayFormat: 'bracket' });
})

const featuredSearchQueryString = computed(() => {
  return QueryString.stringify({ challenge_type: store.getters["filters/getSelectedFilterItems"]["challenge_type"] })
})

const searchApiUrl = computed(() => {
  return CP.env.routes.hackathon_search_api_url +
    (searchQueryString.value ? "?" : "") +
    searchQueryString.value;
});

const searchUrl = computed(() => {
  return CP.env.routes.hackathon_search_url +
    (searchQueryString.value ? "?" : "") +
    searchQueryString.value;
})

const getJson = async (url) => {
  return await fetch(url).then((response) => {
    if (!response.ok)
      return { error: true, status: response.status };

    return response.json();
  });
}

const getFeatured = async () => {
  const featuredHackathonApiUrl = CP.env.routes.featured_hackathon_search_api_url + "?" + featuredSearchQueryString.value;
  return getJson(featuredHackathonApiUrl);
}

const getFeaturedByType = async (challengeType) => {
  const featuredHackathonUrl = CP.env.routes.featured_hackathons_url;
  const queryString = QueryString.stringify({ challenge_type: challengeType }, { arrayFormat: 'bracket' });
  return getJson(featuredHackathonUrl + "?" + queryString);
}

const getFeaturedInPerson = async () => getFeaturedByType("in-person");
const getFeaturedOnline = async () => getFeaturedByType("online");

const getSimilar = async () => {
  return getJson(CP.env.routes.similar_api_hackathon_url);
}

const getNearby = async () => {
  return getJson(CP.env.routes.nearby_hackathons_url);
}

const get = async () => {
  return getJson(searchApiUrl.value);
}

export default {
  searchQueryString,
  searchUrl,
  getFeatured,
  getSimilar,
  getFeaturedInPerson,
  getFeaturedOnline,
  getNearby,
  get
}
