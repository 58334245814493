import UserHackathonApi from "@/api/users/hackathon_api"
import filter from 'lodash/filter'

const state = () => ({
  user_hackathons: []
})

const mutations = {
  setUserHackathons: (state, hackathons) => state.user_hackathons = hackathons
}

const getters = {
  openHackathons: (state) => filter(state.user_hackathons, (hackathon) => hackathon.open_state == "open"),
  endedHackathons: (state) => filter(state.user_hackathons, (hackathon) => hackathon.open_state == "ended"),
  upcomingHackathons: (state) => filter(state.user_hackathons, (hackathon) => hackathon.open_state == "upcoming"),

  totalHackathonCount: (state) => state.user_hackathons.length
}

const actions = {
  async fetchHackathons({ commit }) {
    const response = await UserHackathonApi.registered();

    commit("setUserHackathons", response.hackathons);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
