<template>
  <span href="#" class="label round host-label"
    :title="name"
    @click.prevent="selectOrClearOrganization"
    @mouseenter="onMouseOver"
    @mouseleave="onMouseLeave"
    :class="cssClass"
    tabindex="1">

    <font-awesome-icon :icon="['far', 'check-circle']" class="checkmark"></font-awesome-icon>
    {{name}}
  </span>
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  import FontAwesomeIcon from '@/components/FontAwesomeIcon.vue';

  export default {
    name: "HackathonHost",
    props: {
      name: {
        type: String,
        required: true
      }
    },

    components: {
      FontAwesomeIcon
    },

    emits: ['onMouseOver', 'onMouseLeave'],

    setup(props, { emit }) {
      const store = useStore();

      return {
        selectOrClearOrganization() {
          const selectedOrganizationName = store.getters["filters/getSelectedOrganizationName"];
          store.dispatch(
            "filters/setSelectedOrganization",
            (selectedOrganizationName == props.name) ? null : { name: props.name }
          );
          store.dispatch("hackathons/search");
        },
        cssClass: computed(() => {
          const selectedOrganizationName = store.getters["filters/getSelectedOrganizationName"];
          return (selectedOrganizationName == props.name) ? "selected" : ""
        }),

        onMouseLeave() {
          emit("onMouseLeave");
        },

        onMouseOver() {
          emit("onMouseOver");
        }
      }
    }
  }
</script>
