import { createApp } from 'vue';

const mountApp = ({ domId, component, raiseOnFailure, beforeMount }) => {
  document.addEventListener('DOMContentLoaded', () => {
    if (!document.getElementById(domId)) {
      if (raiseOnFailure) {
        Honeybadger.notify(`Failed to mount app: ${domId}`);
      }

      return;
    }

    const app = createApp(component);

    if (beforeMount) beforeMount(app);

    app.mount(`#${domId}`);
  })
}

export {
  mountApp
}
