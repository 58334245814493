<template>
  <div class="submission-period">
    {{hackathon.submission_period_dates}}
  </div>
</template>

<script>
  export default {
    name: "HackathonSubmissionPeriod",

    props: {
      hackathon: { type: Object, required: true }
    }
  }
</script>
