import { createStore, createLogger } from 'vuex'

import userHackathons from './modules/devpost_homepage/user_hackathons_store'

const store = createStore({
  modules: {
    userHackathons
  }
})

export default store
