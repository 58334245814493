import { mountApp } from '@/apps/mount_app';
import UserHackathons from '@/components/devpost_homepage/UserHackathons.vue'
import store from '@/stores/homepage_app_store'

export default () => {
  mountApp({
    domId: "your-hackathons",
    component: UserHackathons,
    beforeMount: (app) => app.use(store)
  })
}
