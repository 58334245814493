import { onMounted, onUnmounted } from 'vue';
import throttle from 'lodash/throttle';

// Set isMounted to true if:
//   - you're calling onResize within a `onMounted` block
//   - the callback doesn't interact with the DOM
const onResize = (callback, { wait = 100, isMounted = false }) => {
  const onResizeCallback = throttle(callback, wait);

  const setup = () => {
    onResizeCallback();
    window.addEventListener('resize', onResizeCallback);
  }

  if (isMounted) {
    setup();
  } else {
    onMounted(setup);
    onUnmounted(() => window.removeEventListener('resize', onResizeCallback));
  }
}

export default onResize;
