import includes from 'lodash/includes'

const delayBeforeTracking = 2000;
let timeout = null;

const actionsToTrack = [
  "hackathons/search"
]

const trackableState = (store) => {
  return {
    ...store.getters["filters/getSelectedFilterItems"],
    query: store.getters["filters/getQuery"],
    sorting_by: store.getters["sorting/getSortingOptionValue"],
    totalResultCount: store.getters["hackathons/totalResultCount"],
    fuzzySearch: store.getters["hackathons/fuzzySearch"],
    searchError: store.getters["hackathons/searchError"]
  }
}

const MixpanelPlugin = (store) => {
  store.subscribeAction({
    after(action, state) {
      if (!includes(actionsToTrack, action.type)) return;

      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        CP.MixpanelProxy.track(
          "Hackathon search",
          trackableState(store)
        );
      }, delayBeforeTracking);
    }
  })
}

export default MixpanelPlugin;
