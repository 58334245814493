<template>
  <div class="round label status-label mr-4" :class="hackathon.open_state">
    {{hackathon.time_left_to_submission}}
  </div>
</template>

<script>
  export default {
    name: "HackathonStatus",

    props: {
      hackathon: { type: Object, required: true }
    }
  }
</script>
