<template>
  <div>
    <h4 class="mb-2">{{filter.heading}}</h4>

    <dev-select
      @search="onSearch"
      :options="options"
      :placeholder="(selectedOrganization && selectedOrganization.name) || 'Select host'"
      v-model="selectedOrganization"
      :name="filter.paramName">

      <template v-slot:select-an-option>
        Select host
      </template>

      <template v-slot:selected-option="slotProps">
        {{slotProps.option.name}}
      </template>

      <template v-slot:option="slotProps">
        {{slotProps.option.name}}
      </template>

      <template v-slot:no-results="slotProps">
        <template v-if="slotProps.inputValue">No hosts match '{{slotProps.inputValue}}'.</template>
        <template v-else>No hosts found.</template>
      </template>

    </dev-select>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, onMounted, ref } from 'vue';

  import DevSelect from '@/components/DevSelect.vue';

  export default {
    name: "SearchDropdown",
    components: {
      DevSelect
    },
    emits: ["mounted"],
    props: {
      filter: { type: Object, required: true }
    },
    setup(props, { emit }) {
      const store = useStore();
      const filter = props.filter;

      if (store.getters["filters/getOrganizations"].length == 0)
        store.dispatch("filters/searchOrganizations", "");

      onMounted(() => {
        emit("mounted");
      })

      return {
        options: computed(() => store.getters["filters/getOrganizations"]),

        onSearch(term, loading) {
          store.dispatch("filters/searchOrganizations", term);
        },

        selectedOrganization: computed({
          get() { return store.getters["filters/getSelectedOrganization"]; },
          set(value) {
            store.dispatch("filters/setSelectedOrganization", value);
            store.dispatch("hackathons/search");
          }
        })
      }
    }
  }
</script>

<style scoped>
  h4 {
    text-transform: none;
    font-size: 1rem;
  }
</style>
