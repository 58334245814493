import map from 'lodash/map';

const actions = {
  initialiseStore({ dispatch }) {
    const searchParams = (typeof URLSearchParams != "undefined") ?
      new URLSearchParams(decodeURIComponent(window.location.search)) :
      null;

    if (searchParams && searchParams.get("search")) {
      // we clear switch of the eligibility toggle when there's a search param
      // to be consistant with the search bar that clears all filters when searching by keywords
      setEligibilityToggle(false, dispatch);
    } else {
      setEligibilityToggleFromLocalStorage(dispatch);
    }

    setFiltersFromUrlParams(dispatch, searchParams);
  }
}

const setEligibilityToggle = (value, dispatch) => {
  dispatch("filters/setEligibilityToggle", value, { root: true });
}

const setManagedByDevpostBadge = (value, dispatch) => {
  dispatch("filters/setManagedByDevpostBadge", value, { root: true });
}

const setEligibilityToggleFromLocalStorage = (dispatch) => {
  const storage = window.localStorage;
  if (!storage) return;

  const eligibilityFilterSelected = storage.getItem("eligibilityFilterSelected");
  setEligibilityToggle(eligibilityFilterSelected == "true", dispatch);
}

const setFiltersFromUrlParams = (dispatch, searchParams) => {
  if (!searchParams) return;

  const rootDispatch = (actionName, params) => dispatch(actionName, params, { root: true });

  let themes = []

  searchParams.forEach((value, key) => {
    switch (key) {
      case "challenge_type[]":
        rootDispatch("filters/toggleItem", { paramName: "challenge_type", value: value })
        break;
      case "length[]":
        rootDispatch("filters/toggleItem", { paramName: "length", value: value })
        break;
      case "organization":
        rootDispatch("filters/setSelectedOrganization", { name: value })
        break;
      case "page":
        rootDispatch("hackathons/setPage", value)
        break;
      case "search":
        rootDispatch("filters/updateQuery", value)
        break;
      case "order_by":
        rootDispatch("sorting/sortBy", value)
        break;
      case "status[]":
        rootDispatch("filters/toggleItem", { paramName: "status", value: value })
        break;
      case "themes[]":
        themes.push(value);
        break;
      case "open_to[]":
        rootDispatch("filters/toggleItem", { paramName: "open_to", value: value })
        break;
      case "eligibility":
        setEligibilityToggle(true, dispatch);
        break;
      case "managed_by_devpost_badge":
        setManagedByDevpostBadge(true, dispatch);
        break;
    }
  });

  if (themes.length) {
    themes = map(themes, (themeName) => {
      return {
        name: themeName,
        selected: true
      }
    });

    rootDispatch("filters/mergeThemes", themes);
  }
}

export default {
  namespaced: true,
  actions
}
