// Makes the `el` element contained in `scrollableEl` visible if it currently is not.
//
const scrollIntoView = ({ scrollableEl, el, topOffset = 15, bottomOffset = 15 }) => {
  const scrollTop = scrollableEl.scrollTop;
  const scrollBottom = scrollTop + scrollableEl.clientHeight;

  if (el.offsetTop + bottomOffset > scrollBottom) {
    scrollableEl.scrollTop = el.offsetTop - scrollableEl.clientHeight + el.clientHeight;
  } else if (el.offsetTop + el.clientHeight - topOffset < scrollTop) {
    scrollableEl.scrollTop = el.offsetTop;
  }
}

export default scrollIntoView;
