import find from 'lodash/find'

const state = () => ({
  defaultSortingOption: "most-relevant",

  sortingOptions: [
    {
      name: "Most relevant",
      value: "most-relevant",
      selected: true
    },
    {
      name: "Submission date",
      value: "deadline",
      selected: false
    },
    {
      name: "Recently added",
      value: "recently-added",
      selected: false
    },
    {
      name: "Prize amount",
      value: "prize-amount",
      selected: false
    }
  ]
})

const helpers = {
  getSelectedOption(state) {
    return state.sortingOptions.find((option) => option.selected);
  },
  getOptionByValue(state, value) {
    return state.sortingOptions.find((option) => option.value == value);
  }
}

const mutations = {
  select(state, option) {
    option.selected = true;
  },
  unselect(state, option) {
    option.selected = false;
  }
}

const getters = {
  getSortingOptions(state) {
    return state.sortingOptions;
  },
  getSortingOptionValue(state) {
    return helpers.getSelectedOption(state).value;
  },
  getDefaultSortingOption(state) {
    return state.defaultSortingOption;
  }
}

const actions = {
  sortBy({ state, commit, dispatch }, value) {
    const selectedOption = helpers.getSelectedOption(state);

    if (selectedOption.value == value) return;

    const optionToSelect = helpers.getOptionByValue(state, value);

    if (!optionToSelect) return;

    commit("unselect", selectedOption);
    commit("select", optionToSelect);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
