<template>
  <div v-if="anyUserHackathons">
    <header class="mb-7 py-5">
      <div class="row">
        <div class="small-12 columns">
          <div class="flex-row align-items-center">
            <span class="all-caps mr-5">
              <strong>Your hackathons</strong>
            </span>

            <div class="show-for-medium-up">
              <div class="flex-row sections">
                <template v-for="section in sections" :key="section.label">
                  <span v-if="section.hackathons.length" class="stat">
                    {{section.hackathons.length}} {{section.statsLabel}}
                  </span>
                </template>
              </div>
            </div>

            <div class="justify-self-right">
              <a href="" class="button radius mb-0">
                Browse hackathons
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="row">
      <div class="small-12 columns">

        <div id="your-open-hackathons" class="mb-6">

          <template v-for="section in sections" :key="section.label">
            <div v-if="section.hackathons.length" class="mb-3">
              <h3>{{section.heading}}</h3>

              <hackathon-grid :hackathons="section.hackathons" v-slot="props">
                <hackathon-result
                  :isFeatured="false"
                  isSmall
                  :hackathon="props.hackathon"
                  :refParams="{ ref_feature: 'challenge', ref_medium: 'your-open-hackathons', ref_content: section.heading }">
                </hackathon-result>
              </hackathon-grid>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex';
  import { ref, computed } from 'vue';

  import HackathonResult from '@/components/HackathonResult';
  import HackathonGrid from '@/components/HackathonGrid';

  const store = useStore();

  const anyUserHackathons = computed(() => store.getters["userHackathons/totalHackathonCount"] > 0);

  const fetchReq = store.dispatch("userHackathons/fetchHackathons");

  fetchReq.then(() => {
    // We display the header as a fallback
    if (!anyUserHackathons.value) {
      $("[data-homepage-header]").fadeIn();
    }
  });

  const sections = ref([
    {
      hackathons: computed(() => store.getters["userHackathons/openHackathons"]),
      heading: "Submissions open",
      statsLabel: "open"
    },
    {
      hackathons: computed(() => store.getters["userHackathons/endedHackathons"]),
      heading: "Recently ended",
      statsLabel: "recently ended"
    },
    {
      hackathons: computed(() => store.getters["userHackathons/upcomingHackathons"]),
      heading: "Upcoming",
      statsLabel: "upcoming"
    }
  ]);
</script>

<style lang="scss" scoped>
  @import 'colors';

  header {
    background: $grey-1;
    border-bottom: $blue-grey-2;
  }

  h3 {
    font-size: 1.4rem;
  }

  .stat {
    position: relative;
    color: $blue-grey-5;
    font-size: 1.1rem;

    &:not(:first-child) {
      $padding-left: 2rem;
      $dot-size: 4px;

      padding-left: $padding-left;

      &:before {
        content: "";
        height: $dot-size;
        width: $dot-size;
        background: $grey-4;
        border-radius: $dot-size;
        position: absolute;
        left: $padding-left / 2;
        top: 50%;
        margin-left: -$dot-size / 2;
        margin-top: -$dot-size / 2;
      }
    }
  }

  .all-caps {
    letter-spacing: 0;
  }
</style>
